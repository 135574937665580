import { burger } from '../functions/burger';
import GraphModal from 'graph-modal';
import Swiper, { Navigation } from 'swiper';
import Inputmask from 'inputmask';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import eventmove from './jquery.event.move';
import twentytwenty from './jquery.twentytwenty';
import accordion from './accordion';
import fixHeader from './fix-header';
export default function() {
    burger
    window.addEventListener('scroll',fixHeader)
    accordion('.menu-mobile__nav-item', '.menu-mobile__item--nested');
    const inputMask = new Inputmask('+7 (999) 999-99-99');
    inputMask.mask('.modal__input[type="tel"]');
    const modal = new GraphModal();
    Swiper.use([Navigation]);
    const swiper = new Swiper('.js-stocks-slider', {
      slidesPerView: 1,
      slideClass: 'js-stocks-slide',
      wrapperClass: 'js-stocks-wrapper',
      navigation: {
        prevEl: '.js-stocks-prev',
        nextEl: '.js-stocks-next'
      },
      spaceBetween: 30,
      breakpoints: {
        979: {
        slidesPerView: 4,
        },
        662: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 2
        },
        400: {
          slidesPerView: 1.5
        },
        330: {
          slidesPerView: 1.25
        }
      }
    });
    swiper.init();
    eventmove;  
    twentytwenty;
    $(function(){
      $(".gallery__item-wrapper").twentytwenty(
        {
          default_offset_pct: 0.5, // How much of the before image is visible when the page loads
          orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
          before_label: '', // Set a custom before label
          after_label: '', // Set a custom after label
          no_overlay: true, //Do not show the overlay with before and after
          move_slider_on_hover: false, // Move slider on mouse hover?
          move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement. 
          click_to_move: false // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
        }
      );
    });
}
