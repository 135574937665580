export default function form() {
    var form = document.querySelector('.modal__form');
    var tel = document.querySelector('.modal__input[type="tel"]');
    var name = document.querySelector('.modal__input[type="text"]');
    var submit = document.querySelector('.modal__call');
    if (form == undefined || form == null) {
        
    } else {
        form.addEventListener("submit", validate);
        function validate(e) {
            e.preventDefault();
            var er = 0;
            let reg = /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d[- .]?\d\d$/;
            if (!reg.test(tel.value)){
                tel.style.background = "#CF6679";
                tel.focus();
                tel.addEventListener('input', ()=>{
                    tel.style.background = "#fff";
                })
                er++;
            } else {
                tel.style.background = "#fff";
            }
            // reg = /\w+@\w+\.\w{2,20}/gi;
            // if (!reg.test(email.value)){
            //     email.style.background = "#CF6679";
            //     email.focus();
            //     email.addEventListener('input', ()=>{
            //         email.style.background = "#EEF9FF";
            //     })
            //     er++;
            // } else {
            //     email.style.background = "#EEF9FF";
            // }
            reg = /^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u;
            if (name.value.length < 2 || !reg.test(name.value)){
                name.style.background = "#CF6679";
                name.focus();
                name.addEventListener('input', ()=>{
                    name.style.background = "#fff";
                })
                er++;
            } else {
                name.style.background = "#fff";
            }
            if(!er){
                    // thank();
                    sendForm();
                    document.querySelectorAll('.modal__input').forEach(el => {
                        el.value = "";
                    });
                    // check.checked = false;
                    // submit.disabled = true;                
            }
        }
        async function sendForm() {
            var formData = new FormData(form);
            let response = await fetch('../some.php', {
                method: 'POST',
                body: formData
            });
            let result = await response.text();
        }
    }
}