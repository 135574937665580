export default function accordion(selector, element) {
    document.querySelectorAll(selector).forEach(item=>{
        item.addEventListener('click', ()=>{
            let content = item.nextElementSibling;
            if(content.classList.contains('active')) {
                content.querySelectorAll(element).forEach(el=>{
                    if(el.nextElementSibling && el.nextElementSibling.classList.contains('menu-mobile--nested')) {
                        el.addEventListener('click', ()=>{
                            let inner = el.nextElementSibling;
                            if(inner.classList.contains('active')) {
                                el.classList.add('arrow--active');
                                inner.style.maxHeight = null;
                                inner.classList.remove('active');
                            } else {
                                content.querySelectorAll(element).forEach(el=>{
                                    if(el.nextElementSibling) {
                                        el.classList.remove('arrow--active');
                                        el.nextElementSibling.classList.remove('active');
                                        el.nextElementSibling.style.maxHeight = null;
                                    }
                                });
                                inner.style.maxHeight = inner.scrollHeight + 'px';
                                inner.classList.add('active');
                            }
                        })
                    }
                })
                content.style.maxHeight = null;
                content.classList.remove('active');
                document.querySelectorAll(selector).forEach(item=>{
                    if(item.classList.contains('arrow'))
                    item.classList.remove('arrow--active')
                });

            } else {
                content.querySelectorAll(element).forEach(el=>{
                    if(el.nextElementSibling && el.nextElementSibling.classList.contains('menu-mobile--nested')) {
                        el.addEventListener('click', (e)=>{
                            let inner = el.nextElementSibling;
                            if(inner.classList.contains('active')) {
                                inner.style.maxHeight = null;
                                inner.classList.remove('active');
                                el.classList.remove('arrow--active');
                            } else {
                                content.querySelectorAll(element).forEach(el=>{
                                    if(el.nextElementSibling) {
                                        el.classList.remove('arrow--active');
                                        el.nextElementSibling.classList.remove('active');
                                        el.nextElementSibling.style.maxHeight = null;
                                    }
                                });
                                e.target.classList.add('arrow--active');
                                inner.style.maxHeight = inner.scrollHeight + 'px';
                                inner.classList.add('active');
                                content.style.maxHeight = content.scrollHeight + inner.scrollHeight + "px";
                            }
                        })
                    }
                })
                document.querySelectorAll(selector).forEach(item=>{
                    item.nextElementSibling.classList.remove('active');
                    item.nextElementSibling.style.maxHeight = null;
                    item.classList.remove('arrow--active')
                })
                content.style.maxHeight = content.scrollHeight + "px";
                content.classList.add('active');
                if(item.classList.contains('arrow'))
                item.classList.add('arrow--active');
            }
        })
        if(item.nextElementSibling.classList.contains('menu-mobile--nested')) {
            item.classList.add('arrow')
            document.querySelectorAll(element).forEach(el=>{
                if(el.nextElementSibling && el.nextElementSibling.classList.contains('menu-mobile--nested')) {
                    el.classList.add('arrow')
                }
            }) 
        };
    })
}