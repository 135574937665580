export default function fixHeader() {
    let headerHeight = document?.querySelector('.header')?.clientHeight
    let containers = document?.querySelector('.header')?.querySelectorAll('.header__container'),
            displayed = [];
    containers.forEach(el=>{
        if(el.clientHeight > 0)
            displayed.push(el)
    })
    let last = displayed[displayed.length-1];
    if(window.scrollY>=headerHeight/2)
        last.classList.add('header__container--fixed')
    else if(window.scrollY<headerHeight && !document?.body.classList.contains('dis-scroll')) {
        last.classList.remove('header__container--fixed')
    }
        
}